import { Container, Wrapper } from '@datacamp/cdn-ui';
import classNames from 'classnames/bind';
import Image from 'next/image';
import React from 'react';

import { Link } from '@components/Link/Link';
import { publicRoutes } from '@hooks/Routes/routes';

import styles from './UnauthenticatedLayout.module.css';
import cdn77Logo from '../SideNav/cdn77Logo.svg';
import craLogo from '../SideNav/craLogo.svg';

const cx = classNames.bind(styles);

interface IProps {
  maxWidth?: number;
  logoLinkEnabled?: boolean;
  children: React.ReactNode;
}

export const CRA_URL = 'ssladmin.cdn.cra.cz';

export const UnauthenticatedLayout: React.FC<IProps> = ({
  maxWidth = 330,
  logoLinkEnabled = true,
  children,
}) => {
  const [isCra, setIsCra] = React.useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    setIsCra(document.location.host.includes('ssladmin'));
  }, []);

  return (
    <Container className={styles.container} width="sm">
      <div className={styles.wrapper}>
        <Wrapper className={styles.content} maxWidth={maxWidth}>
          <div className={cx('logoWrapper', 'mb-20', { ready: isCra !== undefined })}>
            {logoLinkEnabled ? (
              <Link aria-label="go to Login" href={publicRoutes.auth.login.pathname}>
                {isCra ? (
                  <Image alt="" height={26} src={craLogo} width={88} priority />
                ) : (
                  <Image alt="" height={22} src={cdn77Logo} width={75} priority />
                )}
              </Link>
            ) : (
              <Image alt="" height={20} src={cdn77Logo} width={68} />
            )}
          </div>
          {children}
        </Wrapper>
      </div>
    </Container>
  );
};
